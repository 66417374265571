export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'CompassIcon',
  },
  {
    title: 'Водители',
    icon: 'UsersIcon',
    route: 'driver-list',
  },
  {
    title: 'Поездки yandex',
    icon: 'ClockIcon',
    route: 'report-yandex-trips',
  },
  {
    title: 'Авто',
    icon: 'ChromeIcon',
    children: [
      { title: 'График', icon: 'GridIcon', route: 'cars-plan' },
      { title: 'График (отчет)', icon: 'GridIcon', route: 'cars-plan-report' },
      { title: 'График (журнал)', icon: 'AlignJustifyIcon', route: 'cars-plan-journal' },
      { title: 'Путевые листы', icon: 'GridIcon', route: 'cars-sheets' },
      { title: 'Акты', icon: 'GridIcon', route: 'cars-act' },
      { title: 'Автомобили', icon: 'AlignJustifyIcon', route: 'cars' },
    ],
  },
  {
    title: 'Деньги',
    icon: 'DollarSignIcon',
    children: [
      { title: 'Поручения', icon: 'ArchiveIcon', route: 'package-list' },
      { title: 'Операции', icon: 'ArchiveIcon', route: 'operation-list' },
    ],
  },
  {
    title: 'Настройки',
    icon: 'SettingsIcon',
    children: [
      { title: 'Пользователи', route: 'user-list', icon: 'UsersIcon' },
      { title: 'Справочники', route: 'directories-list', icon: 'FolderIcon' },
    ],
  },
  {
    title: 'Разное',
    icon: 'ActivityIcon',
    children: [
      { title: 'Рассылки', route: 'mailing-list', icon: 'MailIcon' },
    ],
  },
]

export const userRoleMenu = [
  {
    title: 'Dashboard',
    icon: 'CompassIcon',
    route: 'home',
  },
  {
    title: 'График',
    icon: 'GridIcon',
    route: 'lk-plan',
  },
]
